import SettingsContent from '../Wrapper';
import CadenceHome from './Index';
import { frontendURL } from '../../../../helper/URLHelper';

export default {
  routes: [
    {
      path: frontendURL('accounts/:accountId/settings/cadences'),
      component: SettingsContent,
      props: {
        headerTitle: 'CADENCES.HEADER',
        icon: 'arrow-clockwise',
        showNewButton: true,
      },
      children: [
        {
          path: '',
          name: 'cadences_wrapper',
          redirect: 'list',
        },
        {
          path: 'list',
          name: 'cadences_list',
          roles: ['administrator'],
          component: CadenceHome,
        },
      ],
    },
  ],
};
