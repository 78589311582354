<template>
  <modal :show.sync="show" :on-close="onClose">
    <div class="column content-box">
      <woot-modal-header :header-title="pageTitle" />
      <form class="row medium-8" @submit.prevent="editAgent()">
        <div class="medium-12 columns">
          <label :class="{ error: $v.agentName.$error }">
            {{ $t('AGENT_MGMT.EDIT.FORM.NAME.LABEL') }}
            <input
              v-model.trim="agentName"
              type="text"
              :placeholder="$t('AGENT_MGMT.EDIT.FORM.NAME.PLACEHOLDER')"
              @input="$v.agentName.$touch"
            />
          </label>
        </div>

        <div class="medium-12 columns">
          <label :class="{ error: $v.agentType.$error }">
            {{ $t('AGENT_MGMT.EDIT.FORM.AGENT_TYPE.LABEL') }}
            <select v-model="agentType">
              <option v-for="role in roles" :key="role.name" :value="role.name">
                {{ role.label }}
              </option>
            </select>
            <span v-if="$v.agentType.$error" class="message">
              {{ $t('AGENT_MGMT.EDIT.FORM.AGENT_TYPE.ERROR') }}
            </span>
          </label>
        </div>

        <div v-if="canAddRules" class="medium-12 columns rules">
          <div class="row">
            <div class="medium-6">
              <label class="flex-container">
                {{ $t('AGENT_MGMT.RULES.UNASSIGNED') }}
                <woot-switch v-model="agentRules.unassigned" />
              </label>
            </div>

            <div class="medium-6">
              <label class="flex-container">
                {{ $t('AGENT_MGMT.RULES.CONTACT') }}
                <woot-switch v-model="agentRules.contact" />
              </label>
            </div>

            <div class="medium-6">
              <label class="flex-container">
                {{ $t('AGENT_MGMT.RULES.MASK_NUMBER') }}
                <woot-switch v-model="agentRules.mask_number" />
              </label>
            </div>
          </div>
        </div>

        <div class="medium-12 columns">
          <label :class="{ error: $v.agentAvailability.$error }">
            {{ $t('PROFILE_SETTINGS.FORM.AVAILABILITY.LABEL') }}
            <select v-model="agentAvailability">
              <option
                v-for="role in availabilityStatuses"
                :key="role.value"
                :value="role.value"
              >
                {{ role.label }}
              </option>
            </select>
            <span v-if="$v.agentAvailability.$error" class="message">
              {{ $t('AGENT_MGMT.EDIT.FORM.AGENT_AVAILABILITY.ERROR') }}
            </span>
          </label>
        </div>

        <div class="medium-12 columns">
          <label class="multiselect-wrap--small">
            {{ $t('AGENT_MGMT.EDIT.FORM.TEAMS.LABEL') }}
            <multiselect
              v-model="agentTeams"
              :options="getTeams"
              track-by="id"
              label="name"
              selected-label
              :multiple="true"
              :close-on-select="false"
              :clear-on-select="false"
              :hide-selected="true"
              :placeholder="$t('AGENT_MGMT.EDIT.FORM.TEAMS.PLACEHOLDER')"
              :select-label="$t('FORMS.MULTISELECT.ENTER_TO_SELECT')"
              :deselect-label="$t('FORMS.MULTISELECT.ENTER_TO_REMOVE')"
            />
          </label>
        </div>

        <div class="medium-12 columns">
          <label class="multiselect-wrap--small">
            {{ $t('AGENT_MGMT.EDIT.FORM.INBOXES.LABEL') }}
            <multiselect
              v-model="agentInboxes"
              :options="getInboxes"
              track-by="id"
              label="name"
              selected-label
              :multiple="true"
              :close-on-select="false"
              :clear-on-select="false"
              :hide-selected="true"
              :placeholder="$t('AGENT_MGMT.EDIT.FORM.INBOXES.PLACEHOLDER')"
              :select-label="$t('FORMS.MULTISELECT.ENTER_TO_SELECT')"
              :deselect-label="$t('FORMS.MULTISELECT.ENTER_TO_REMOVE')"
            />
          </label>
        </div>

        <div class="medium-12 templates">
          <label class="flex-container">
            {{ $t('AGENT_MGMT.EDIT.TEMPLATE_LIMIT.LABEL') }}

            <woot-switch v-model="agentTemplateLimit.enable_template_limit" />
          </label>

          <div v-if="agentTemplateLimit.enable_template_limit" class="row">
            <div class="medium-4">
              <label
                :class="{
                  error: $v.agentTemplateLimit.daily_template_limit.$error,
                }"
              >
                {{ $t('AGENT_MGMT.EDIT.TEMPLATE_LIMIT.DAILY.LABEL') }}

                <input
                  min="1"
                  type="number"
                  v-model="agentTemplateLimit.daily_template_limit"
                  @input="$v.agentTemplateLimit.daily_template_limit.$touch"
                />
                <span
                  v-if="$v.agentTemplateLimit.daily_template_limit.$error"
                  class="message"
                >
                  {{ $t('AGENT_MGMT.EDIT.TEMPLATE_LIMIT.DAILY.ERROR') }}
                </span>
              </label>
            </div>

            <div class="medium-7">
              <label
                :class="{
                  error: $v.agentTemplateLimit.monthly_template_limit.$error,
                }"
              >
                {{ $t('AGENT_MGMT.EDIT.TEMPLATE_LIMIT.MONTHLY.LABEL') }}

                <input
                  min="1"
                  type="number"
                  v-model="agentTemplateLimit.monthly_template_limit"
                  @input="$v.agentTemplateLimit.monthly_template_limit.$touch"
                />
                <span
                  v-if="$v.agentTemplateLimit.monthly_template_limit.$error"
                  class="message"
                >
                  {{ $t('AGENT_MGMT.EDIT.TEMPLATE_LIMIT.MONTHLY.ERROR') }}
                </span>
              </label>
            </div>
          </div>
        </div>

        <div v-if="showResetPassword" class="medium-12 columns">
          <label :class="{ error: $v.agentCredentials.password.$error }">
            {{ $t('SET_NEW_PASSWORD.TITLE') }}
            <input
              v-model.trim="agentCredentials.password"
              type="password"
              :placeholder="$t('SET_NEW_PASSWORD.PASSWORD.PLACEHOLDER')"
              @input="$v.agentCredentials.password.$touch"
            />
            <template
              v-if="
                $v.agentCredentials.password.$dirty &&
                $v.agentCredentials.password.$invalid
              "
            >
              <span
                v-if="!$v.agentCredentials.password.containsUppercase"
                class="message"
              >
                {{ $t('SET_NEW_PASSWORD.RECOVER_PASSWORD.UPPERCASE') }}
              </span>
              <span
                v-if="!$v.agentCredentials.password.containsLowercase"
                class="message"
              >
                {{ $t('SET_NEW_PASSWORD.RECOVER_PASSWORD.LOWERCASE') }}
              </span>
              <span
                v-if="!$v.agentCredentials.password.containsNumber"
                class="message"
              >
                {{ $t('SET_NEW_PASSWORD.RECOVER_PASSWORD.NUMBER') }}
              </span>
              <span
                v-if="!$v.agentCredentials.password.containsSpecial"
                class="message"
              >
                {{ $t('SET_NEW_PASSWORD.RECOVER_PASSWORD.SPECIAL') }}
              </span>
            </template>
          </label>
        </div>

        <div class="medium-12 modal-footer">
          <div class="medium-6 columns">
            <woot-submit-button
              :disabled="
                $v.agentType.$invalid ||
                $v.agentName.$invalid ||
                (showResetPassword && $v.agentCredentials.password.$invalid) ||
                (agentTemplateLimit.enable_template_limit &&
                  $v.agentTemplateLimit.$invalid) ||
                uiFlags.isUpdating
              "
              :button-text="$t('AGENT_MGMT.EDIT.FORM.SUBMIT')"
              :loading="uiFlags.isUpdating"
            />
            <button class="button clear" @click.prevent="onClose">
              {{ $t('AGENT_MGMT.EDIT.CANCEL_BUTTON_TEXT') }}
            </button>
          </div>
          <div class="medium-6 columns text-right">
            <woot-button
              type="button"
              icon="lock-closed"
              variant="clear"
              @click="showResetPassword = !showResetPassword"
            >
              {{ $t('SET_NEW_PASSWORD.TITLE') }}
            </woot-button>

            <woot-button
              icon="mail"
              variant="clear"
              @click.prevent="resetPassword"
            >
              {{ $t('SET_NEW_PASSWORD.RECOVER_PASSWORD.LABEL') }}
            </woot-button>
          </div>
        </div>
      </form>
    </div>
  </modal>
</template>

<script>
import {
  required,
  minLength,
  requiredIf,
  minValue,
} from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';
import WootSubmitButton from '../../../../components/buttons/FormSubmitButton';
import Modal from '../../../../components/Modal';
import Auth from '../../../../api/auth';
import wootConstants from 'dashboard/constants/globals';

const { AVAILABILITY_STATUS_KEYS } = wootConstants;

export default {
  components: {
    WootSubmitButton,
    Modal,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    email: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
    teams: {
      type: Array,
      default: [],
    },
    inboxes: {
      type: Array,
      default: [],
    },
    availability: {
      type: String,
      default: '',
    },
    rules: {
      type: [Object, null],
      default: () => {},
    },
    enable_template_limit: {
      type: Boolean,
      default: false,
    },
    daily_template_limit: {
      type: [Number, null],
      default: null,
    },
    monthly_template_limit: {
      type: [Number, null],
      default: null,
    },
    onClose: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      roles: [
        {
          name: 'administrator',
          label: this.$t('AGENT_MGMT.AGENT_TYPES.ADMINISTRATOR'),
        },
        {
          name: 'supervisor',
          label: this.$t('AGENT_MGMT.AGENT_TYPES.SUPERVISOR'),
        },
        {
          name: 'agent',
          label: this.$t('AGENT_MGMT.AGENT_TYPES.AGENT'),
        },
        {
          name: 'seller',
          label: this.$t('AGENT_MGMT.AGENT_TYPES.SELLER'),
        },
        {
          name: 'analyzer',
          label: this.$t('AGENT_MGMT.AGENT_TYPES.ANALYZER'),
        },
        {
          name: 'assistant',
          label: this.$t('AGENT_MGMT.AGENT_TYPES.ASSISTANT'),
        },
        {
          name: 'embed',
          label: this.$t('AGENT_MGMT.AGENT_TYPES.EMBED'),
        },
      ],
      agentName: this.name,
      agentAvailability: this.availability,
      agentType: this.type,
      agentTeams: this.teams,
      agentInboxes: this.inboxes,
      agentCredentials: {
        email: this.email,
        password: null,
      },
      agentRules: {
        mask_number: this.rules?.mask_number ?? true,
        unassigned: this.rules?.unassigned ?? true,
        contact: this.rules?.contact ?? true,
      },
      show: true,
      showResetPassword: false,
      agentTemplateLimit: {
        enable_template_limit: this.enable_template_limit,
        daily_template_limit: this.daily_template_limit,
        monthly_template_limit: this.monthly_template_limit,
      },
    };
  },
  validations() {
    let validations = {
      agentName: {
        required,
        minLength: minLength(1),
      },
      agentType: {
        required,
      },
      agentAvailability: {
        required,
      },
      agentCredentials: {
        password: {
          minLength: minLength(6),
          required: requiredIf(function () {
            return this.showResetPassword === true;
          }),
          containsUppercase: function (value) {
            return /[A-Z]/.test(value);
          },
          containsLowercase: function (value) {
            return /[a-z]/.test(value);
          },
          containsNumber: function (value) {
            return /[0-9]/.test(value);
          },
          containsSpecial: function (value) {
            return /[#?!@$%^&*-]/.test(value);
          },
        },
      },
      agentTemplateLimit: {
        daily_template_limit: {
          minValue: minValue(1),
          required: requiredIf(function () {
            return (
              this.agentTemplateLimit.enable_template_limit &&
              !this.agentTemplateLimit.monthly_template_limit
            );
          }),
        },
        monthly_template_limit: {
          minValue: minValue(this.agentTemplateLimit.daily_template_limit),
          required: requiredIf(function () {
            return (
              this.agentTemplateLimit.enable_template_limit &&
              !this.agentTemplateLimit.daily_template_limit
            );
          }),
        },
      },
    };

    return validations;
  },
  computed: {
    pageTitle() {
      return `${this.$t('AGENT_MGMT.EDIT.TITLE')} - ${this.name}`;
    },
    ...mapGetters({
      uiFlags: 'agents/getUIFlags',
      getTeams: 'teams/getTeams',
      getInboxes: 'inboxes/getInboxes',
    }),
    availabilityStatuses() {
      return this.$t('PROFILE_SETTINGS.FORM.AVAILABILITY.STATUSES_LIST').map(
        (statusLabel, index) => ({
          label: statusLabel,
          value: AVAILABILITY_STATUS_KEYS[index],
          disabled:
            this.currentUserAvailability === AVAILABILITY_STATUS_KEYS[index],
        })
      );
    },
    canAddRules() {
      return this.agentType === 'seller';
    },
  },
  methods: {
    showAlert(message) {
      bus.$emit('newToastMessage', message);
    },
    async editAgent() {
      try {
        const {
          enable_template_limit,
          daily_template_limit,
          monthly_template_limit,
        } = this.agentTemplateLimit;

        const data = {
          id: this.id,
          name: this.agentName,
          role: this.agentType,
          password: this.agentCredentials.password,
          availability: this.agentAvailability,
          inboxes: this.agentInboxes.map(({ id }) => id),
          teams: this.agentTeams.map(({ id }) => id),
          enable_template_limit,
        };

        if (this.canAddRules) {
          data.rules = this.agentRules;
        }

        if (enable_template_limit) {
          data.daily_template_limit = daily_template_limit;
          data.monthly_template_limit = monthly_template_limit;
        }

        await this.$store.dispatch('agents/update', data);

        this.showAlert(this.$t('AGENT_MGMT.EDIT.API.SUCCESS_MESSAGE'));
        this.onClose();
      } catch (error) {
        console.log(error.message);
        this.showAlert(this.$t('AGENT_MGMT.EDIT.API.ERROR_MESSAGE'));
      }
    },
    async resetPassword() {
      try {
        await Auth.resetPassword(this.agentCredentials);
        this.showAlert(
          this.$t('AGENT_MGMT.EDIT.PASSWORD_RESET.ADMIN_SUCCESS_MESSAGE')
        );
      } catch (error) {
        this.showAlert(this.$t('AGENT_MGMT.EDIT.PASSWORD_RESET.ERROR_MESSAGE'));
      }
    },
  },
};
</script>


<style lang="scss" scoped>
.rules {
  border: 1px solid var(--s-200);
  padding: 9px 0px 0px 10px;
  border-radius: var(--border-radius-normal);
  margin-bottom: var(--space-snug);

  .row div {
    margin-bottom: var(--space-slab);

    label {
      button {
        margin-left: var(--space-small);
      }
    }
  }
}

.templates {
  border: 1px solid var(--s-200);
  padding: 9px 0px 0px 10px;
  border-radius: var(--border-radius-normal);
  margin-bottom: var(--space-snug);

  label {
    button {
      margin-left: var(--space-small);
      margin-bottom: var(--space-snug);
    }
  }

  input[type='number'] {
    width: var(--space-mega);
  }

  .row label {
    font-size: var(--font-size-mini);
  }
}
</style>