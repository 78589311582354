<template>
  <div class="column content-box">
    <alert
      :show="true"
      :title="$t('WHATSAPP_TEMPLATES.ALERT.TITLE')"
      :content="$t('WHATSAPP_TEMPLATES.ALERT.CONTENT')"
      type="warning"
    />

    <woot-modal-header
      :header-title="$t('CAMPAIGN.ADD.TITLE')"
      :header-content="$t('CAMPAIGN.ADD.DESC')"
    />

    <timeline :current_page="page" :total="5" />

    <form class="row" @submit.prevent="addCampaign">
      <div class="medium-12 columns">
        <div v-if="page === 1">
          <woot-input
            v-model="title"
            :label="$t('CAMPAIGN.ADD.FORM.TITLE.LABEL')"
            type="text"
            :class="{ error: $v.title.$error }"
            :error="$v.title.$error ? $t('CAMPAIGN.ADD.FORM.TITLE.ERROR') : ''"
            :placeholder="$t('CAMPAIGN.ADD.FORM.TITLE.PLACEHOLDER')"
            @blur="$v.title.$touch"
          />
        </div>

        <div v-if="page === 2">
          <label :class="{ error: $v.selectedInbox.$error }">
            {{ $t('CAMPAIGN.ADD.FORM.INBOX.LABEL') }}
            <select v-model="selectedInbox" @change="onChangeInbox($event)">
              <option v-for="item in inboxes" :key="item.name" :value="item.id">
                {{ item.name }}
              </option>
            </select>
            <span v-if="$v.selectedInbox.$error" class="message">
              {{ $t('CAMPAIGN.ADD.FORM.INBOX.ERROR') }}
            </span>
          </label>

          <label
            class="multiselect-wrap--small"
            :class="{ error: $v.selectedAudience.$error }"
          >
            {{ $t('CAMPAIGN.ADD.FORM.AUDIENCE.LABEL') }}
            <multiselect
              v-model="selectedAudience"
              :options="audienceList"
              track-by="id"
              label="title"
              :multiple="true"
              :close-on-select="false"
              :clear-on-select="false"
              :hide-selected="true"
              :placeholder="$t('CAMPAIGN.ADD.FORM.AUDIENCE.PLACEHOLDER')"
              selected-label
              :select-label="$t('FORMS.MULTISELECT.ENTER_TO_SELECT')"
              :deselect-label="$t('FORMS.MULTISELECT.ENTER_TO_REMOVE')"
              @blur="$v.selectedAudience.$touch"
              @select="$v.selectedAudience.$touch"
            />
            <span v-if="$v.selectedAudience.$error" class="message">
              {{ $t('CAMPAIGN.ADD.FORM.AUDIENCE.ERROR') }}
            </span>
          </label>

          <label :class="{ error: $v.selectedStatus.$error }">
            {{ $t('CAMPAIGN.ADD.FORM.STATUS.LABEL') }}
            <select v-model="selectedStatus">
              <option
                v-for="status in conversationStatuses"
                :key="status.id"
                :value="status.id"
              >
                {{ status.value.toLowerCase() }}
              </option>
            </select>
            <span v-if="$v.selectedStatus.$error" class="message">
              {{ $t('CAMPAIGN.ADD.FORM.STATUS.ERROR') }}
            </span>
          </label>
        </div>

        <div v-if="page === 3">
          <label :class="{ error: $v.selectedTemplate.$error }">
            {{ $t('CAMPAIGN.ADD.FORM.TEMPLATE.LABEL') }}

            <templates-picker
              v-if="!selectedTemplate"
              :inbox-id="selectedInbox"
              @onSelect="pickTemplate"
            />

            <span v-if="$v.selectedTemplate.$error" class="message">
              {{ $t('CAMPAIGN.ADD.FORM.TEMPLATE.ERROR') }}
            </span>
          </label>
        </div>

        <div v-if="page === 4">
          <label :class="{ error: $v.attachedFiles.$error }">
            <span v-if="$v.attachedFiles.$error" class="message custom-error">
              {{ $t('CAMPAIGN.ADD.FORM.FILE.ERROR') }}
            </span>
          </label>

          <template-parser
            v-if="selectedTemplate"
            type="campaign"
            :template="selectedTemplate"
            @sendMessage="getEventTemplate"
          />
        </div>

        <div v-if="page === 5">
          <label :class="{ error: $v.selectedSender.$error }">
            {{ $t('CAMPAIGN.ADD.FORM.SENT_BY.LABEL') }}
            <select
              v-model="selectedSender"
              :disabled="isWhatsappType && !selectedInbox"
            >
              <option
                v-for="sender in senderList"
                :key="sender.name"
                :value="sender.id"
              >
                {{ sender.name }}
              </option>
            </select>
            <span v-if="$v.selectedSender.$error" class="message">
              {{ $t('CAMPAIGN.ADD.FORM.SENT_BY.ERROR') }}
            </span>
          </label>

          <label>
            {{ $t('CAMPAIGN.ADD.FORM.SCHEDULED_AT.LABEL') }}
            <woot-date-time-picker
              :value="scheduledAt"
              :confirm-text="$t('CAMPAIGN.ADD.FORM.SCHEDULED_AT.CONFIRM')"
              :placeholder="$t('CAMPAIGN.ADD.FORM.SCHEDULED_AT.PLACEHOLDER')"
              @change="onChange"
            />
          </label>
        </div>
      </div>

      <div class="modal-footer">
        <woot-button
          @click.prevent="changePage('minus')"
          :isDisabled="canDisableButton"
        >
          {{ $t('GENERAL_SETTINGS.BACK') }}
        </woot-button>

        <template v-if="page !== 4">
          <woot-button v-if="canChangePage" @click.prevent="changePage('add')">
            {{ $t('CAMPAIGN.NEXT.BUTTON_TEXT') }}
          </woot-button>

          <woot-button v-else :is-loading="uiFlags.isCreating">
            {{ $t('CAMPAIGN.ADD.CREATE_BUTTON_TEXT') }}
          </woot-button>
        </template>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { required, requiredIf } from 'vuelidate/lib/validators';
import FileUpload from 'vue-upload-component';

import { CAMPAIGNS_EVENTS } from '../../../../../helper/AnalyticsHelper/events';

import Alert from 'shared/components/Alert';
import AttachmentPreview from 'dashboard/components/widgets/AttachmentsPreview';
import WootDateTimePicker from 'dashboard/components/ui/DateTimePicker';
import TemplatesPicker from 'dashboard/components/widgets/conversation/WhatsappTemplates/TemplatesPicker';
import TemplateParser from 'dashboard/components/widgets/conversation/WhatsappTemplates/TemplateParser';
import Timeline from 'shared/components/Timeline';

import alertMixin from 'shared/mixins/alertMixin';
import campaignMixin from 'shared/mixins/campaignMixin';
import snoozeTimesMixin from 'dashboard/mixins/conversation/snoozeTimesMixin';

export default {
  components: {
    Alert,
    AttachmentPreview,
    FileUpload,
    TemplatesPicker,
    TemplateParser,
    Timeline,
    WootDateTimePicker,
  },
  mixins: [alertMixin, campaignMixin, snoozeTimesMixin],
  data() {
    return {
      title: '',
      message: '',
      selectedSender: null,
      selectedInbox: null,
      selectedAudience: [],
      selectedTemplate: null,
      selectedStatus: 1,
      scheduledAt: null,
      senderList: [],
      templateParams: null,
      attachedFiles: null,
      page: 1,
    };
  },
  validations() {
    return {
      title: { required },
      message: { required },
      selectedInbox: { required },
      selectedSender: { required },
      selectedTemplate: { required },
      selectedStatus: { required },
      attachedFiles: {
        required: requiredIf(function () {
          return (
            this.formatTypeTemplateHeader &&
            this.formatTypeTemplateHeader != 'TEXT'
          );
        }),
      },
      selectedAudience: {
        isEmpty() {
          return !!this.selectedAudience.length;
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'campaigns/getUIFlags',
      audienceList: 'labels/getLabels',
      accountId: 'getCurrentAccountId',
      currentUser: 'getCurrentUser',
    }),
    inboxes() {
      return this.$store.getters['inboxes/getWhatsAppInboxes'];
    },
    conversationStatuses() {
      return [
        {
          id: 1,
          key: 'open',
          value: this.$t('CONVERSATION.CARD_CONTEXT_MENU.OPEN'),
        },
        {
          id: 2,
          key: 'resolved',
          value: this.$t('CONVERSATION.CARD_CONTEXT_MENU.RESOLVED'),
        },
        {
          id: 3,
          key: 'pending',
          value: this.$t('CONVERSATION.CARD_CONTEXT_MENU.PENDING'),
        },
        {
          id: 4,
          key: 'snoozed',
          value: `
            ${this.$t('CONVERSATION.RESOLVE_DROPDOWN.SNOOZE.TITLE')}
            ${this.$t('CONVERSATION.RESOLVE_DROPDOWN.SNOOZE.TOMORROW')}
          `,
          snooze: this.snoozeTimes.tomorrow,
        },
        {
          id: 5,
          key: 'snoozed',
          value: `
            ${this.$t('CONVERSATION.RESOLVE_DROPDOWN.SNOOZE.TITLE')}
            ${this.$t('CONVERSATION.RESOLVE_DROPDOWN.SNOOZE.NEXT_WEEK')}
          `,
          snooze: this.snoozeTimes.nextWeek,
        },
        {
          id: 6,
          key: 'snoozed',
          value: `
            ${this.$t('CONVERSATION.RESOLVE_DROPDOWN.SNOOZE.TITLE')}
            ${this.$t('CONVERSATION.RESOLVE_DROPDOWN.SNOOZE.NEXT_REPLY')}
          `,
        },
      ];
    },
    canChangePage() {
      const { page } = this;

      if (page === 5) {
        return false;
      }

      return true;
    },
    canDisableButton() {
      return this.page === 1 || (this.page === 5 && this.uiFlags.isCreating);
    },
    formatTypeTemplateHeader() {
      if (this.selectedTemplate) {
        const [header] = this.selectedTemplate.components.filter(({ type }) => {
          return type == 'HEADER';
        });

        return header?.format;
      }
    },
    getParams() {
      window.history.pushState({}, null, `${this.$route.path}?page=1`);

      return { page: 1, sortAttr: '-created_at', search: '' };
    },
  },
  mounted() {
    this.$track(CAMPAIGNS_EVENTS.OPEN_NEW_CAMPAIGN_MODAL, {
      type: this.campaignType,
    });
  },
  methods: {
    async onChangeInbox() {
      try {
        const response = await this.$store.dispatch('inboxMembers/get', {
          inboxId: this.selectedInbox,
        });
        const {
          data: { payload: inboxMembers },
        } = response;
        this.senderList = inboxMembers;
      } catch (error) {
        const errorMessage =
          error?.response?.message || this.$t('CAMPAIGN.ADD.API.ERROR_MESSAGE');
        this.showAlert(errorMessage);
      }
    },
    async addCampaign() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      }

      try {
        const campaignDetails = this.getCampaignDetails();

        await this.$store.dispatch('campaigns/create', campaignDetails);
        await this.$store.dispatch(
          'campaigns/getCampaignWhatsapp',
          this.getParams
        );

        // tracking this here instead of the store to track the type of campaign
        this.$track(CAMPAIGNS_EVENTS.CREATE_CAMPAIGN, {
          type: this.campaignType,
        });

        this.showAlert(this.$t('CAMPAIGN.ADD.API.SUCCESS_MESSAGE'));
        this.onClose();
      } catch (error) {
        const errorMessage =
          error?.response?.message || this.$t('CAMPAIGN.ADD.API.ERROR_MESSAGE');
        this.showAlert(errorMessage);
      }
    },
    onClose() {
      this.$emit('on-close');
    },
    onChange(value) {
      this.scheduledAt = value;
    },
    pickTemplate(template) {
      this.selectedTemplate = template;
      this.changePage('add');
    },
    getEventTemplate(event) {
      if (event.files) {
        this.attachedFiles = event.attachedFile;
      }

      if (!event.files) {
        const { message, templateParams } = event;

        this.message = message;
        this.templateParams = templateParams;
      }

      this.changePage('add');
    },
    getCampaignDetails() {
      const audience = this.selectedAudience.map(item => {
        return {
          id: item.id,
          type: 'Label',
        };
      });

      const [status] = this.conversationStatuses.filter(({ id }) => {
        return this.selectedStatus == id;
      });

      let campaignDetails = {
        title: this.title,
        message: this.message,
        inbox_id: this.selectedInbox,
        scheduled_at: this.scheduledAt,
        sender_id: this.selectedSender,
        audience,
        trigger_rules: {
          status: status.key,
          template_params: this.templateParams,
        },
      };

      if (status.snooze) {
        campaignDetails.trigger_rules.snoozed_until = status.snooze;
      }

      if (this.attachedFiles) {
        campaignDetails.file = this.attachedFiles;
      }

      return campaignDetails;
    },
    changePage(type) {
      const { page } = this;

      if (type === 'minus' && page > 1) {
        if (page === 4) {
          this.selectedTemplate = null;
          this.attachedFiles = null;

          this.$v.$reset();
        }

        --this.page;
      }

      if (type === 'add' && page < 5) {
        this.$v.$touch();

        if (
          (page === 1 && this.$v.title.$error) ||
          (page === 2 &&
            (this.$v.selectedInbox.$error ||
              this.$v.selectedAudience.$error ||
              this.$v.selectedStatus.$error)) ||
          (page === 3 && this.$v.selectedTemplate.$error) ||
          (page === 4 && this.$v.attachedFiles.$error) ||
          (page === 5 && this.$v.selectedSender.$error)
        ) {
          return;
        }

        this.$v.$reset();

        ++this.page;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.message {
  margin-top: var(--space-micro);

  &.custom-error {
    position: absolute;
    margin-top: var(--space-jumbo);
    left: var(--space-large);
    width: 50%;
  }
}

.modal-footer {
  button {
    margin-left: 0 !important;
  }

  width: 100%;
  justify-content: space-between !important;
}
</style>