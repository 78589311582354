import {
  format,
  isSameYear,
  fromUnixTime,
  formatDistanceToNow,
} from 'date-fns';

export default {
  methods: {
    messageStamp(time, dateFormat = 'h:mm a') {
      const unixTime = fromUnixTime(time);
      return format(unixTime, dateFormat);
    },
    messageTimestamp(time, dateFormat = 'MMM d, yyyy') {
      const messageTime = fromUnixTime(time);
      const now = new Date();
      const messageDate = format(messageTime, dateFormat);
      if (!isSameYear(messageTime, now)) {
        return format(messageTime, 'LLL d y, h:mm a');
      }
      return messageDate;
    },
    dynamicTime(time) {
      const unixTime = fromUnixTime(time);
      return formatDistanceToNow(unixTime, { addSuffix: true });
    },
    dateFormat(time, dateFormat = 'MMM d, yyyy') {
      const unixTime = fromUnixTime(time);
      return format(unixTime, dateFormat);
    },
    dateFormatPatternIntl(time, dateTimeFormat = 'pt-BR', timeZone = 'America/Sao_Paulo') {
      const date = new Date(time);
      const options = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: false,
        timeZone: timeZone,
      };

      return new Intl.DateTimeFormat(dateTimeFormat, options).format(date);
    },
    longTimestamp(time) {
      const convertToLongTime = time
        .replace(/about/g, this.$t('CHAT_LIST.TIME.ABOUT'))
        .replace(/over/g, this.$t('CHAT_LIST.TIME.OVER'))
        .replace(/almost/g, this.$t('CHAT_LIST.TIME.ALMOST'))
        .replace('less than a minute ago', this.$t('CHAT_LIST.TIME.LESS_THAN_A_MINUTE_AGO'))
        .replace(' minute ago', this.$t('CHAT_LIST.TIME.MINUTE_AGO'))
        .replace(' minutes ago', this.$t('CHAT_LIST.TIME.MINUTES_AGO'))
        .replace('a minute ago', this.$t('CHAT_LIST.TIME.A_MINUTE_AGO'))
        .replace('an hour ago', this.$t('CHAT_LIST.TIME.AN_HOUR_AGO'))
        .replace(' hour ago', this.$t('CHAT_LIST.TIME.HOUR_AGO'))
        .replace(' hours ago', this.$t('CHAT_LIST.TIME.HOURS_AGO'))
        .replace(' day ago', this.$t('CHAT_LIST.TIME.DAY_AGO'))
        .replace('a day ago', this.$t('CHAT_LIST.TIME.A_DAY_AGO'))
        .replace(' days ago', this.$t('CHAT_LIST.TIME.DAYS_AGO'))
        .replace('a month ago', this.$t('CHAT_LIST.TIME.A_MONTH_AGO'))
        .replace(' months ago', this.$t('CHAT_LIST.TIME.MONTHS_AGO'))
        .replace(' month ago', this.$t('CHAT_LIST.TIME.MONTH_AGO'))
        .replace('a year ago', this.$t('CHAT_LIST.TIME.A_YEAR_AGO'))
        .replace(' year ago', this.$t('CHAT_LIST.TIME.YEAR_AGO'))
        .replace(' years ago', this.$t('CHAT_LIST.TIME.YEARS_AGO'));
      return convertToLongTime;
    },
    shortTimestamp(time) {
      const convertToShortTime = time
        .replace(/about|over|almost|/g, '')
        .replace('less than a minute ago', this.$t('CHAT_LIST.TIME.NOW'))
        .replace(' minute ago', this.$t('CHAT_LIST.TIME.MINUTE'))
        .replace(' minutes ago', this.$t('CHAT_LIST.TIME.MINUTE'))
        .replace('a minute ago', this.$t('CHAT_LIST.TIME.MINUTE'))
        .replace('an hour ago', this.$t('CHAT_LIST.TIME.HOUR'))
        .replace(' hour ago', this.$t('CHAT_LIST.TIME.HOUR'))
        .replace(' hours ago', this.$t('CHAT_LIST.TIME.HOUR'))
        .replace(' day ago', this.$t('CHAT_LIST.TIME.DAY'))
        .replace('a day ago', this.$t('CHAT_LIST.TIME.DAY'))
        .replace(' days ago', this.$t('CHAT_LIST.TIME.DAYS'))
        .replace('a month ago', this.$t('CHAT_LIST.TIME.MONTH'))
        .replace(' months ago', this.$t('CHAT_LIST.TIME.MONTHS'))
        .replace(' month ago', this.$t('CHAT_LIST.TIME.MONTH'))
        .replace('a year ago', this.$t('CHAT_LIST.TIME.YEAR'))
        .replace(' year ago', this.$t('CHAT_LIST.TIME.YEAR'))
        .replace(' years ago', this.$t('CHAT_LIST.TIME.YEARS'));
      return convertToShortTime;
    },
  },
};
