<template>
  <div class="columns">
    <div v-if="height === 'auto'" class="text-center">
      <loading-state />
    </div>

    <div class="bg-image" :style="getClasses">
      <div class="card">
        <div v-if="hasHeader" class="header">
          <div v-if="headerIsMediaType" class="media">
            <img :src="getMediaUrl" alt="Whatsapp template icon" />
          </div>

          <p v-else>{{ getTextHeader }}</p>
        </div>

        <div class="body">
          <p>{{ getTextBody }}</p>
        </div>

        <div v-if="hasFooter" class="footer">
          <p>{{ getTextFooter }}</p>
        </div>

        <div v-if="hasButtons" class="buttons">
          <div v-if="getButtons.quantity === 1" class="medium-6 end-right">
            <span>
              <fluent-icon :icon="getButtons.data[0].icon" />
              {{ getButtons.data[0].text }}
            </span>
          </div>

          <div v-else-if="getButtons.quantity > 1" class="row">
            <div class="medium-6">
              <span>
                <fluent-icon :icon="getButtons.data[0].icon" />
                {{ getButtons.data[0].text }}
              </span>
            </div>

            <div class="medium-6">
              <span>
                <fluent-icon :icon="getButtons.data[1].icon" />
                {{ getButtons.data[1].text }}
              </span>
            </div>

            <div v-if="getButtons.quantity > 2" class="medium-12">
              <span>
                <fluent-icon :icon="getButtons.data[2].icon" />
                {{ getButtons.data[2].text }}
              </span>
            </div>
          </div>

          <div v-if="getButtons.quantity > 3" class="row">
            <div class="medium-12">
              <span>
                <fluent-icon icon="list" />
                {{ $t('WHATSAPP_TEMPLATES.ADD.FORM.CONTENT.PREVIEW.SEE_ALL') }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingState from 'dashboard/components/widgets/LoadingState';

import templateWppMixin from 'shared/mixins/templateWppMixin';

export default {
  components: {
    LoadingState,
  },
  mixins: [templateWppMixin],
  props: {
    template: {
      type: Object,
      default: () => {},
    },
    types: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      height: 'auto',
    };
  },
  computed: {
    hasHeader() {
      return !!this.template.header_parameters;
    },
    hasFooter() {
      return !!this.template.footer_parameters;
    },
    hasButtons() {
      return !!this.template.button_parameters;
    },
    headerIsMediaType() {
      if (this.hasHeader) {
        const { image, document, video } = this.types;
        const medias = [image.key, document.key, video.key];

        return medias.includes(this.template.header_parameters.format);
      }
    },
    getMediaUrl() {
      if (this.headerIsMediaType) {
        const urlBase = '/assets/images/templates';

        const { image, document, video } = this.types;
        const { format } = this.template.header_parameters;

        if (format === image.key) return `${urlBase}/image.svg`;
        if (format === document.key) return `${urlBase}/document.svg`;
        if (format === video.key) return `${urlBase}/video.svg`;
      }
    },
    getTextHeader() {
      if (this.hasHeader) {
        const { header_parameters: header } = this.template;

        return header.text.replace(/{{([^}]+)}}/g, (match, variable) => {
          const variableKey = this.processVariable(variable);
          const { header_text } = header.example;

          return header_text[Number(variableKey) - 1] || `{{${variable}}}`;
        });
      }
    },
    getTextBody() {
      const { body_parameters: body } = this.template;

      return body.text.replace(/{{([^}]+)}}/g, (match, variable) => {
        const variableKey = this.processVariable(variable);
        const { body_text } = body.example;

        return body_text[0][Number(variableKey) - 1] || `{{${variable}}}`;
      });
    },
    getTextFooter() {
      if (this.hasFooter) {
        return this.template.footer_parameters.text;
      }
    },
    getButtons() {
      if (this.hasButtons) {
        const { buttons } = this.template.button_parameters;

        const data = buttons.map(({ text, type }) => {
          let icon = 'call';

          if (type === 'URL') icon = 'link';
          if (type === 'QUICK_REPLY') icon = 'arrow-reply';

          return { text, icon };
        });

        return { quantity: buttons.length, data };
      }
    },
    getClasses() {
      return {
        height: this.height,
        opacity: this.height == 'auto' ? 0 : 1,
      };
    },
  },
  methods: {
    setHeight() {
      setTimeout(() => {
        const content = document.querySelector('.bg-image .card');
        this.height = `${content.clientHeight + 80}px`;
      }, 100);
    },
  },
  mounted() {
    this.setHeight();
  },
};
</script>

<style lang="scss" scoped>
.bg-image {
  background-image: url('/assets/images/templates/background.jpg');
  width: 100%;
  background-size: cover;
  margin-bottom: var(--space-large);
  border-radius: var(--border-radius-large);

  .card {
    position: absolute;
    margin-top: var(--space-large);
    margin-left: var(--space-large);
    overflow: visible;
    width: 490px;
    border: 0;

    &::after {
      content: '';
      position: absolute;
      left: -22px;
      bottom: var(--space-one);
      border: 12px solid;
      border-color: transparent var(--white) transparent transparent;
    }

    p {
      font-size: 13px;
      line-height: normal;
    }

    .header {
      p {
        font-weight: 900;
        color: black;
        margin-bottom: var(--space-two);
      }

      .media {
        margin-bottom: var(--space-two);
      }
    }

    .footer {
      p {
        margin-top: var(--space-two);
        font-size: 10px !important;
      }
    }

    .buttons {
      margin-top: var(--space-two);

      .end-right {
        margin-left: auto;
      }

      span {
        display: flex;
        padding: 5px 10px;
        align-items: center;
        justify-content: center;
        border: var(--space-micro) solid var(--w-400);
        border-radius: var(--border-radius-normal);
        font-size: var(--font-size-small);
        font-weight: var(--font-weight-bold);
        color: var(--w-400);
        margin-right: var(--space-smaller);
        margin-left: var(--space-smaller);
        margin-bottom: var(--space-smaller);
      }

      svg {
        margin-right: var(--space-smaller);
      }
    }
  }
}
</style>