import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser',
      currentAccountId: 'getCurrentAccountId',
      currentChat: 'getSelectedChat',
      currentRole: 'getCurrentRole',
      agentList: 'agents/getAgents',
      currentUserID: 'getCurrentUserID',
    }),
    assignableAgents() {
      return this.$store.getters['inboxAssignableAgents/getAssignableAgents'](
        this.inboxId
      );
    },
    isAgentSelected() {
      return this.currentChat?.meta?.assignee;
    },
    createNoneAgent() {
      return {
        confirmed: true,
        name: 'None',
        id: 0,
        role: 'agent',
        account_id: 0,
        email: 'None',
      };
    },
    agentRules() {
      if (this.currentRole === 'seller') {
        const user = this.agentList.find(({ id }) => id === this.currentUserID);

        return user?.rules;
      }

      return null;
    },
    agentsList() {
      let agents = this.assignableAgents || [];

      if (Object.keys(this.currentChat).length > 0 &&
        this.currentChat.meta?.team &&
        this.currentChat.meta.team.id !== 0
      ) {
        agents = this.agentList.filter(agent => {
          return agent.teams.some(team => team.id === this.currentChat.meta.team.id)
        });
      }

      const agentsByUpdatedPresence = this.getAgentsByUpdatedPresence(agents);
      const none = this.createNoneAgent;
      const filteredAgentsByAvailability = this.sortedAgentsByAvailability(
        agentsByUpdatedPresence
      );
      const filteredAgents = [
        ...(this.isAgentSelected ? [none] : []),
        ...filteredAgentsByAvailability,
      ];
      return filteredAgents;
    },
  },
  methods: {
    getAgentsByAvailability(agents, availability) {
      return agents
        .filter(agent => agent.availability_status === availability)
        .sort((a, b) => a.name.localeCompare(b.name));
    },
    sortedAgentsByAvailability(agents) {
      const onlineAgents = this.getAgentsByAvailability(agents, 'online');
      const busyAgents = this.getAgentsByAvailability(agents, 'busy');
      const offlineAgents = this.getAgentsByAvailability(agents, 'offline');
      const filteredAgents = [...onlineAgents, ...busyAgents, ...offlineAgents];
      return filteredAgents;
    },
    getAgentsByUpdatedPresence(agents) {
      // Here we are updating the availability status of the current user dynamically (live) based on the current account availability status
      const agentsWithDynamicPresenceUpdate = agents.map(item =>
        item.id === this.currentUser.id
          ? {
            ...item,
            availability_status: this.currentUser.accounts.find(
              account => account.id === this.currentAccountId
            ).availability_status,
          }
          : item
      );
      return agentsWithDynamicPresenceUpdate;
    },
  },
};
