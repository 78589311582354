<template>
  <campaign-whatsapp v-if="isWhatsappType" />

  <div v-else class="column content-box">
    <woot-button
      color-scheme="success"
      class-names="button--fixed-top"
      icon="add-circle"
      @click="openAddPopup"
    >
      {{ buttonText }}
    </woot-button>

    <campaign />

    <woot-modal :show.sync="showAddPopup" :on-close="hideAddPopup">
      <add-campaign @on-close="hideAddPopup" />
    </woot-modal>
  </div>
</template>

<script>
import campaignMixin from 'shared/mixins/campaignMixin';
import Campaign from './Campaign.vue';
import CampaignWhatsapp from './whatsapp/CampaignView';
import AddCampaign from './AddCampaign';

export default {
  components: {
    Campaign,
    CampaignWhatsapp,
    AddCampaign,
  },
  mixins: [campaignMixin],
  data() {
    return { showAddPopup: false };
  },
  computed: {
    buttonText() {
      if (this.isOngoingType) {
        return this.$t('CAMPAIGN.HEADER_BTN_TXT.ONGOING');
      }

      if (this.isOnOffType) {
        return this.$t('CAMPAIGN.HEADER_BTN_TXT.ONE_OFF');
      }
    },
  },
  mounted() {
    if (!this.isWhatsappType) this.$store.dispatch('campaigns/get');
  },
  methods: {
    openAddPopup() {
      this.showAddPopup = true;
    },
    hideAddPopup() {
      this.showAddPopup = false;
    },
  },
};
</script>
